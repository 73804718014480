import React, { useState, useCallback, useEffect } from "react";
import "../styles.css";
import { useLocation } from 'react-router-dom';
import axios from "axios";
 
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
 
const subscriptionKey = process.env.REACT_APP_AZURE_SPEECH_KEY;
const serviceRegion = process.env.REACT_APP_AZURE_SPEECH_LOCATION;
 
// Speech configuration outside the component to avoid re-initialization
const speechConfig = sdk.SpeechConfig.fromSubscription(
  subscriptionKey,
  serviceRegion
);
 
speechConfig.speechSynthesisVoiceName = "hi-IN-SwaraNeural";
 
const Bot = () => {
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [appState, setAppState] = useState("idle");
  const [chatResponse, setChatResponse] = useState("");
  const [error, setError] = useState("");
  const [showSpeechBubble, setShowSpeechBubble] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [speechBubbleTimeout, setSpeechBubbleTimeout] = useState(2000);
  const [resTime, setResTime] = useState();
  const [usage, setUsage] = useState(0);
  const [userId, setUserId] = useState("");
  const location = useLocation();

  // Parse the query string to get the 'id' parameter
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

 console.log(id);
  const handleCallButtonClick = async () => {
    // await createUserId();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/start-the-call`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      // Check if the response was successful before parsing the data
      if (!response.ok) {
        const errorData = await response.json();
        console.log("Error:", errorData);
        return;
      }
      const data = await response.json();
      console.log("data", data);
      setUserId(data.userId);
      processTranscript("hello", new Date().getTime(), data.userId);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  // useEffect(() => {
  //   if (userId) {
  //     processTranscript("hello", new Date().getTime(), userId); // Call processTranscript after userId is
  //   }
  // }, [userId]); // This ensures processTranscript is called only after userId is updated
 
  const startSpeechRecognition = useCallback((userid) => {
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
 
    const autoDetectSourceLanguageConfig =
      sdk.AutoDetectSourceLanguageConfig.fromLanguages([
        "en-US",
        "hi-IN",
        "mr-IN",
        "kn-IN",
        "gu-IN",
      ]);
    autoDetectSourceLanguageConfig.mode = sdk.LanguageIdMode.Continuous;
    console.log(autoDetectSourceLanguageConfig);
    const speechRecognizer = sdk.SpeechRecognizer.FromConfig(
      speechConfig,
      autoDetectSourceLanguageConfig,
      audioConfig
    );
 
    speechRecognizer.recognizeOnceAsync((result) => {
      const languageDetectionResult =
        sdk.AutoDetectSourceLanguageResult.fromResult(result);
      const detectedLanguage = languageDetectionResult.language;
      switch (result.reason) {
        case sdk.ResultReason.RecognizedSpeech:
          setShowSpeechBubble(true);
          setTranscript(result.text || "No content received");
          const startTime = new Date().getTime();
          console.log(userId);
          processTranscript(result.text, startTime, userid);
          break;
        case sdk.ResultReason.NoMatch:
          console.log("NOMATCH: Speech could not be recognized.");
          toggleRecording();
          break;
        case sdk.ResultReason.Canceled:
          const cancellation = sdk.CancellationDetails.fromResult(result);
          console.log(`CANCELED: Reason=${cancellation.reason}`);
          if (cancellation.reason === sdk.CancellationReason.Error) {
            console.log(`CANCELED: ErrorCode=${cancellation.ErrorCode}`);
            console.log(`CANCELED: ErrorDetails=${cancellation.errorDetails}`);
          }
          break;
      }
    });
    //
    setShowSpeechBubble(true);
  }, []);
 
  const processTranscript = async (text, startTime, userid) => {
    try {
      setAppState("processing");
      setShowSpinner(true);
      console.log("ProcessTranscriptId", userid);
      const formData = new FormData();
      formData.append("query", text);
      formData.append("user_id", userid);
      formData.append("bot_id",`${id}`);
 
      const response = await axios.post(
        process.env.REACT_APP_API_URL,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
 
      const responseText = response.data.response[0];
      const totalTokens = response.data.response[1].total_tokens;
      const totalUsage = usage + totalTokens;
 
      setUsage(totalUsage);
      setChatResponse(responseText || "No content received");
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { query: text, response: responseText },
      ]);
 
      speak(responseText, userid);
      // setTimeout(() => setShowSpeechBubble(false), speechBubbleTimeout);
      const endTime = new Date().getTime();
      const responseTime = endTime - startTime;
      setResTime(responseTime);
    } catch (error) {
      setError(`Error processing transcript: ${error.message}`);
    } finally {
      setAppState("idle");
      setShowSpinner(false);
    }
  };
 
  const speak = useCallback((text, userid) => {
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
 
    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          setTimeout(
            () => toggleRecording(userid),
            result.privAudioDuration / 10000
          );
        }
        synthesizer.close();
      },
      (error) => {
        console.error("Speech synthesis failed:", error);
        synthesizer.close();
      }
    );
  }, []);
 
  const toggleRecording = (userid) => {
    setIsListening(true);
    setAppState("listening");
    startSpeechRecognition(userid);
  };
 
  const toggleChat = () => setIsChatOpen((prevState) => !prevState);
  const endCall = async () => {
    window.location.reload();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/complete-the-call`,
        {
          user_id: userId,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
 
      // Optional: Handle the response if needed
      console.log("Call ended successfully:", response.data);
    } catch (err) {
      // Handle the error
      console.error("Error ending the call:", err);
    }
  };
 
  return (
    <div className="containerr">
      <header className="app-header">
        <h1 className="text-3xl py-2">HamariAI Voice Bot</h1>
        
        <p className="app-subtext">Time: {resTime}ms</p>
        <p className="app-subtext">Usage: {usage}</p>
      </header>
 
      <div className={`speech-bubble ${showSpeechBubble ? "visible" : ""}`}>
        {transcript}
      </div>
 
      <div className={`app-state-indicator ${appState}`}>
        {appState === "listening" && (
          <div className="listening-bubble">You can speak now...</div>
        )}
        <button className="record-btn" onClick={toggleRecording}>
          {showSpinner && <div className="spinner"></div>}
        </button>
 
        <button
          className="start-btn"
          onClick={handleCallButtonClick}
          disabled={appState !== "idle"}
        >
          Call
        </button>
 
        <button
          className="stop-btn"
          onClick={() => {
            endCall();
          }}
        >
          Hang Up
        </button>
      </div>
 
      <div className={`chat-sidebar ${isChatOpen ? "open" : ""}`}>
        <div className="chat-content">
          {chatHistory.map((entry, index) => (
            <div key={index} className="chat-entry">
              <div className="user-query">User: {entry.query}</div>
              <div className="system-response">Response: {entry.response}</div>
            </div>
          ))}
        </div>
      </div>
 
      <button className="chat-toggle-button" onClick={toggleChat}>
        {isChatOpen ? "<" : ">"}
      </button>
    </div>
  );
};
 
export default Bot;