import React from 'react';
import "./index.css";
import { BrowserRouter,Router,Routes,Route } from 'react-router-dom';
import Bot from './components/Bot';
import HomePage from './components/HomePage.jsx';
import Train from './components/Training.jsx';
import TopNavbar from './components/navbar/TopNavbar.jsx';
const App = () => {
  return (
    <>
<BrowserRouter>

  <Routes>
    <Route path='/' element={<HomePage/>}/>
    <Route path='/training' element={<Train/>}/>
    <Route path='/bot' element={<Bot/>}/>
  </Routes>

</BrowserRouter>
    </>
  )
}

export default App
