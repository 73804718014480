import React,{useState,useEffect} from 'react'
import TopNavbar from './navbar/TopNavbar';
import { useLocation } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";

const Train = () => {

  const [botName,setBotName] = useState(null);
  const [botType,setBotType] = useState(null);
  const [botInstruction,setBotInstruction] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [uploadSuccess,setUploadSuccess] = useState(false);
  const fileInputRef = React.createRef(); // Reference to file input

  const location = useLocation();
  const handleFileChange = (e) => {
    setDocumentFile(e.target.files[0]);
    setUploadSuccess(true);
  };
  const handleClick = () => {
    // Trigger file input click when the user clicks on the drop zone
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    setDocumentFile(file);
  };


  console.log(documentFile)
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!botName || !botType || !botInstruction || !documentFile) {
      alert("Please fill out all fields and upload a document.");
      return;
    }

    const formData = new FormData();
    formData.append('bot_name', botName);
    formData.append('bot_type', botType);
    formData.append('instruction', botInstruction);
    formData.append('file', documentFile);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-documents`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        alert('Bot creation successful!');
      } else {
        alert('Error creating bot.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send data.');
    }
  };





  return (
    <>
<TopNavbar/>
     
      <div className='w-full  px-4 flex flex-col  align-center justify-center min-h-screen overflow-auto'>
        <div className='w-full flex align-center justify-center my-4'>
          <h2 className='text-4xl font-bold'>

            Create bot
          </h2>
        </div>
        
<form onSubmit={handleFormSubmit}>
    <div className="grid gap-6 px-8 mb-6 md:grid-cols-2">
        <div>
            <label for="first_name" className="flex block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bot Name<p className='text-red-900 px-1'> *</p></label>
            <input onChange={(e)=>setBotName(e.target.value)} type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Lisa" required />
        </div>
        <div>
            <label for="last_name" className="flex block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bot Type <p className='text-red-900 px-1'> *</p></label>
            <input onChange={(e)=>setBotType(e.target.value)} type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" required />
        </div>
        <div>
            <label for="company" className="flex block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Instructions <p className='text-red-900 px-1'> *</p></label>
            <input onChange={(e)=>setBotInstruction(e.target.value)}  type="text" id="company" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Flowbite" required />
        </div>  
        <div>
            <label for="guardrail" className="flex block mb-2 text-sm font-medium text-gray-900 dark:text-white">Do not's for bot <p className='text-red-900 px-1'> *</p></label>
            <input type="text" id="guardrail" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder=""  required />
        </div>
       </div>
   
</form>
<div className='px-16'>


<div className="px-16">
          <div
            className={`flex items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 ${
              dragging ? 'border-blue-500 bg-blue-100' : 'border-gray-300'
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClick} // Handle click to upload
          >
          {
            uploadSuccess?(
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <FaCheckCircle />

              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Document Uploaded Successfully</span> 
              </p>
              
            </div>
            ):(
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-sm text-gray-500">.pdf, .txt, file</p>
            </div>
            )
          }
            <input
              id="dropzone-file"
              type="file"
              onChange={handleFileChange}
              className="hidden"
              ref={fileInputRef} // Reference the input for click-to-upload
              accept=".pdf,.txt"
              required
            />
          </div>

        </div>
        </div>

        
          <div className="flex items-center justify-center mb-4">
            <p className="text-md text-gray-700 dark:text-gray-300">
              Selected file:{documentFile && ( documentFile.name)}
            </p>
          </div>
      
        <div className='flex items-center justify-center w-full"'>

        
        <button onClick={handleFormSubmit}  type="submit" className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md px-8 py-3 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
    
        </div>
       

      </div>
     



    </>
  )
}

export default Train;